import * as React from "react"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import Subscribe from "../../components/subscribe"
import { StaticImage } from "gatsby-plugin-image"

const jobOpenings = [
  {
    id: 1,
    role: "There are no openings at this time",
    flyer: null,
    description:
      "Please check back later or subscribe to our newsletter to be notified when a position is posted.",
    apply: null,
  },
  // {
  //   id: 2,
  //   role: "Executive Director",
  //   flyer: "https://drive.google.com/uc?export=download&id=1FIPVM6pt_UU3-3v1RGYhROnnom8SYoNv",
  //   description:
  //     "The next Executive Director of Women in Neuroscience will steward our transition from start-up to powerhouse. We are looking for a strategic and visionary thinker who can keep us moving on the path forward while navigating the daily tasks and opportunities of WiN's world.",
  //   apply: "https://bit.ly/applyWiN",
  // },
]

const winternjobPage = () => (
  <Layout siteTitle={"Become a WiNtern"}>
    <main>
      <div className="relative px-6 lg:px-8">
        <div className="mx-auto max-w-3xl pt-20 pb-32 sm:pt-48 sm:pb-40">
          <div>
            <div>
              <h1 className="text-4xl font-bold tracking-tight sm:text-center sm:text-6xl">
                We're always looking for awesome people to join us!
              </h1>
            </div>

            <div className="z-0 absolute inset-x-0 top-[calc(100%-13rem)] transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
              <svg
                className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
                viewBox="0 0 1155 678"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
                  fillOpacity=".3"
                  d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
                />
                <defs>
                  <linearGradient
                    id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
                    x1="1155.49"
                    x2="-78.208"
                    y1=".177"
                    y2="474.645"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#9089FC" />
                    <stop offset={1} stopColor="#FF80B5" />
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
        </div>
      </div>
    </main>

    <div className="mx-auto mb-28 mt-3 max-w-7xl px-6 sm:mt-3 lg:px-8">
  <div>
    <div className="relative">
      <div className="mx-auto flex max-w-2xl flex-col items-start justify-between gap-16 lg:mx-0 lg:max-w-none lg:flex-row relative z-10">
        <div className="w-full lg:max-w-lg lg:flex-auto">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Current Openings</h2>
          <p className="mt-6 text-xl font-light leading-8 text-gray-600">
            At Women in Neuroscience (WiN), we are committed to empowering the next generation of female scientists through our internship program, mentorship opportunities, and educational initiatives. If you're as passionate about creating a more inclusive and equitable neuroscience community as we are, check out our open positions and apply today!
          </p>
          <StaticImage
            src="../../images/openpositions.jpg"
            alt="Group photo of WiN employees"
            className="mt-10 aspect-[6/5] w-full rounded-2xl bg-gray-50 object-cover lg:aspect-auto lg:h-[34.5rem]"
          />
        </div>

        <div className="mt-16 w-full lg:max-w-xl lg:flex-auto">
          <h3 className="sr-only">Job openings</h3>
          <ul className="-my-8 divide-y divide-gray-100">
          {jobOpenings.map((opening) => (
            <li key={opening.id} className="py-8">
              <dl className="relative flex flex-wrap gap-x-3">
                <dt className="sr-only">Role</dt>
                <dd className="w-full flex-none text-lg font-semibold tracking-tight text-gray-900">
                  {opening.role}
                </dd>
                <dt className="sr-only">Description</dt>
                <dd className="mt-2 w-full flex-none text-base leading-7 text-gray-600">
                  {opening.description}
                  <svg
                    viewBox="0 0 2 2"
                    className="ml-3 inline h-0.5 w-0.5 flex-none fill-gray-300"
                    aria-hidden="true"
                  >
                    <circle cx={1} cy={1} r={1} />
                  </svg>
                </dd>
                {opening.flyer && (
                  <>
                    <dt className="sr-only">Full Job Description</dt>
                    <dd className="mt-4">
                      <a
                        href={opening.flyer}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-base leading-7 text-indigo-600 hover:text-indigo-500 hover:underline"
                      >
                        Full Description <span aria-hidden="true">&rarr;</span>
                      </a>
                    </dd>
                  </>
                )}
                {opening.apply && (
                  <>
                    <dt className="sr-only">Apply</dt>
                    <dd className="mt-4">
                      <a
                        href={opening.apply}
                        className="text-base leading-7 text-indigo-600 hover:text-indigo-500 hover:underline"
                      >
                        Apply Now <span aria-hidden="true">&rarr;</span>
                      </a>
                    </dd>
                  </>
                )}
              </dl>
            </li>
          ))} 
          </ul>
          {/* <div className="mt-8 flex border-t border-gray-100 pt-8">
            <a href="#" className="text-sm font-semibold leading-6 text-indigo-600 hover:text-indigo-500">
              Check back soon for new postings! <span aria-hidden="true">&rarr;</span>
            </a>
          </div> */}
        </div>
      </div>
    </div>

    <div className="absolute inset-x-0 top-[calc(100%-13rem)] transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]">
      <svg
        className="relative left-[calc(50%+3rem)] h-[21.1875rem] max-w-none -translate-x-1/2 sm:left-[calc(50%+36rem)] sm:h-[42.375rem]"
        viewBox="0 0 1155 678"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="url(#ecb5b0c9-546c-4772-8c71-4d3f06d544bc)"
          fillOpacity=".3"
          d="M317.219 518.975L203.852 678 0 438.341l317.219 80.634 204.172-286.402c1.307 132.337 45.083 346.658 209.733 145.248C936.936 126.058 882.053-94.234 1031.02 41.331c119.18 108.451 130.68 295.337 121.53 375.223L855 299l21.173 362.054-558.954-142.079z"
        />
        <defs>
          <linearGradient
            id="ecb5b0c9-546c-4772-8c71-4d3f06d544bc"
            x1="1155.49"
            x2="-78.208"
            y1=".177"
            y2="474.645"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#9089FC" />
            <stop offset={1} stopColor="#FF80B5" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  </div>
</div>

    {/* email subscribe */}
    <Subscribe />
  </Layout>
)

export const Head = () => <Seo title="Open Positions at WiN" />

export default winternjobPage